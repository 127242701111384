import {
  AccountDetailList,
  AccountDetailListParams,
} from '../__generated__/pge-types';
import { DocumentNode } from 'graphql';
import compact from 'lodash/compact';
import useAuthQuery from './useAuthQuery';
import {
  makeParams,
  AccountListState,
} from '../components/account-summary/multi-accounts/accountListReducer';
import { useApolloClient } from '@apollo/react-hooks';
type Arguments = {
  accountListParams: AccountListState;
  skip?: boolean;
};
/**
 * A wrapper around a getAccountDetailList operation.
 * Used the returned setters to manipulate and re-run the query passed in.
 * Handles building the variables based on state.
 *
 * Use returned getters to get portions of the result data.
 *
 * @param {DocumentNode} gql - the query to be executed
 * @param {Arguments} [arguments] - The arguments to use when pulling the list:
 * - accountListParams - used to change the page, filter, sort options, etc.
 * In most cases `src/components/account-summary/multi-accounts/accountListReducer.ts`
 * can be used to manage account list params.
 * - skip - Used to skip the query (same as the skip param for `useQuery`
 */
export default function useAccountDetailList(
  gql: DocumentNode,
  groupId: string | undefined,
  { accountListParams, skip }: Arguments,
) {
  const apolloClient = useApolloClient();
  const variables = {
    params: makeParams(groupId!, accountListParams),
  };
  const { error, data, loading, refetch } = useAuthQuery<
    { getAccountDetailList: AccountDetailList },
    { params: AccountDetailListParams }
  >(gql, {
    variables,
    skip: skip || !groupId,
  });
  const detailList = data?.getAccountDetailList;

  const totalCount = detailList?.totalCount;
  const accounts = compact(detailList?.accounts);

  function writeAccountInfoCache(queryData: Partial<AccountDetailList>) {
    apolloClient.cache.writeQuery({
      query: gql,
      data: {
        getAccountDetailList: {
          ...detailList,
          ...queryData,
        },
        variables,
      },
    });
  }
  return {
    loading,
    error,
    data,
    detailList,
    totalCount,
    accounts,
    groupId,
    refetch,

    writeAccountInfoCache,
  };
}
